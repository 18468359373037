{
  "From": "From",
  "To": "To",
  "Get your price": "Get your price",
  "City": "City",
  "Page not found": "Page not found",
  "This is not the page you were looking for.": "This is not the page you were looking for.",
  "Back": "Back",
  "Contact us": "Contact us",
  "Privacy policy": "Privacy policy",
  "Terms and Conditions": "Terms and Conditions",
  "Powered by SWIDA Innovative © 2023 - instacarrier.eu": "Powered by SWIDA Innovative © 2023 - instacarrier.eu",
  "Need help?": "Need help?",
  "How it works": "How it works",
  "Services and benefits": "Services and benefits",
  "Get FREE Quote": "Get FREE Quote",
  "Instant quote and order": "Instant quote and order",
  "Send a transportation request": "Send a transportation request",
  "Get the quote": "Get the quote",
  "Book transport": "Book transport",
  "Transport confirmed": "Transport confirmed",
  "Just in 2 hours": "Just in 2 hours",
  "Enjoy delivery and online dashboard/tracking": "Enjoy delivery and online dashboard/tracking",
  "Truck\\'s licence plate info": "Truck\\'s licence plate info",
  "Pickup confirmed": "Pickup confirmed",
  "Delivery tracking": "Delivery tracking",
  "Delivery confirmed": "Delivery confirmed",
  "Payment": "Payment",
  "Get the instant transport price.": "Get the instant transport price.",
  "InstaCarrier is a service provided by SWIDA Innovative company. We provide more than 40 000 transports around Europe per year. See what is included in the service.": "InstaCarrier is a service provided by SWIDA Innovative company. We provide more than 40 000 transports around Europe per year. See what is included in the service.",
  "Custom transport request": "Custom transport request",
  "Create a quick transport request.": "Create a quick transport request.",
  "Price quote": "Price quote",
  "We provide instant freight quotes without unnecessary waiting.": "We provide instant freight quotes without unnecessary waiting.",
  "Online booking": "Online booking",
  "Just 2 hours": "Just 2 hours",
  "From order to pickup from your location.": "From order to pickup from your location.",
  "License plate provided": "License plate provided",
  "Truck\\'s license plate is shared with you immediately after order confirmation.": "Truck\\'s license plate is shared with you immediately after order confirmation.",
  "Pickup and delivery confirmation": "Pickup and delivery confirmation",
  "Is provided online by the Detail Page and notifications.": "Is provided online by the Detail Page and notifications.",
  "Stay updated during the transport.": "Stay updated during the transport.",
  "Pay later": "Pay later",
  "For verified clients, payment by invoice is possible.": "For verified clients, payment by invoice is possible.",
  "not-found": "not-found",
  "Transport Request": "Transport Request",
  "Describe your transport": "Describe your transport",
  "satisfied clients": "satisfied clients",
  "transports on the road right now": "transports on the road right now",
  "Thank you for the price request.": "Thank you for the price request.",
  "This is a beta version of the InstaCarrier platform with a limited capacity for automated processing. However, we will be delighted to attend to you in person.": "This is a beta version of the InstaCarrier platform with a limited capacity for automated processing. However, we will be delighted to attend to you in person.",
  "Please choose your preferred method of contact:": "Please choose your preferred method of contact:",
  "Do you have an idea about how to improve our services?": "Do you have an idea about how to improve our services?",
  "Thank you.": "Thank you.",
  "We will contact you ASAP": "We will contact you ASAP",
  "VAT": "VAT",
  "Checking limit": "Checking limit",
  "Retry": "Retry",
  "The entered VAT ID was not found. Please enter company data manually.": "The entered VAT ID was not found. Please enter company data manually.",
  "Enter your billing information": "Enter your billing information",
  "Vat number": "Vat number",
  "Country": "Country",
  "Autofill company details": "Autofill company details",
  "Company name": "Company name",
  "Address": "Address",
  "ZIP": "ZIP",
  "Continue": "Continue",
  "Billing information": "Billing information",
  "Payment method": "Payment method",
  "Confirmation": "Confirmation",
  "Could not initialize payment": "Could not initialize payment",
  "Could not confirm order": "Could not confirm order",
  "Could not save information": "Could not save information",
  "Could not save payment": "Could not save payment",
  "Order confirmation and payment": "Order confirmation and payment",
  "Order confirmation": "Order confirmation",
  "Price is above insurance limit": "Price is above insurance limit",
  "Select payment method": "Select payment method",
  "Please choose and confirm payment method you prefer": "Please choose and confirm payment method you prefer",
  "City is required": "City is required",
  "Country is required": "Country is required",
  "Company or name": "Company or name",
  "Close": "Close",
  "Get your price in minutes": "Get your price in minutes",
  "Pickup in 2 hours": "Pickup in 2 hours",
  "Remove": "Remove",
  "COUNT OF UNITS": "COUNT OF UNITS",
  "Type of Packaging": "Type of Packaging",
  "Unit Width": "Unit Width",
  "Unit height": "Unit height",
  "Unit Weight": "Unit Weight",
  "Stackable packaging": "Stackable packaging",
  "+ Add another cargo": "+ Add another cargo",
  "Total weight": "Total weight",
  "Special requirements": "Special requirements",
  "TailLift": "TailLift",
  "ADR": "ADR",
  "I agree with <a>Terms and conditions</a>": "I agree with <a>Terms and conditions</a>",
  "Email is required": "Email is required",
  "Name is required": "Name is required",
  "Phone is required": "Phone is required",
  "Your name and surname": "Your name and surname",
  "Work e-mail": "Work e-mail",
  "Phone number": "Phone number",
  "YOUR NAME": "YOUR NAME",
  "YOUR WORK E-MAIL": "YOUR WORK E-MAIL",
  "PHONE NUMBER (OPTIONAL)": "PHONE NUMBER (OPTIONAL)",
  "MESSAGE": "MESSAGE",
  "Pickup place is required": "Pickup place is required",
  "Delivery place is required": "Delivery place is required",
  "Enter pickup location": "Enter pickup location",
  "Enter delivery location": "Enter delivery location",
  "The selected datetime cannot be in the past": "The selected datetime cannot be in the past",
  "Choose one": "Choose one",
  "Count is required": "Count is required",
  "Count should be greater than or equal to 1": "Count should be greater than or equal to 1",
  "Length is required": "Length is required",
  "Length should be greater than or equal to 1": "Length should be greater than or equal to 1",
  "Width is required": "Width is required",
  "Width should be greater than or equal to 1": "Width should be greater than or equal to 1",
  "Height is required": "Height is required",
  "Height should be greater than or equal to 1": "Height should be greater than or equal to 1",
  "Unit weight is required": "Unit weight is required",
  "Unit weight should be greater than or equal to 1": "Unit weight should be greater than or equal to 1",
  "PICKUP": "PICKUP",
  "DELIVERY": "DELIVERY",
  "Transport Duration": "Transport Duration",
  "Estimated Distance": "Estimated Distance",
  "Freight details": "Freight details",
  "Define your cargo details below, you can select from various packaging and vehicle types.": "Define your cargo details below, you can select from various packaging and vehicle types.",
  "Additional notes": "Additional notes",
  "Anything else you want to add?": "Anything else you want to add?",
  "Enter your notes here...": "Enter your notes here...",
  "Your price quote is on its way.": "Your price quote is on its way.",
  "We are very sorry the InstaCarrier platform did not fulfill your expectations, yet. Let us work on it for a few more weeks and inform you about its full glorious launch to the world.": "We are very sorry the InstaCarrier platform did not fulfill your expectations, yet. Let us work on it for a few more weeks and inform you about its full glorious launch to the world.",
  "Check your inbox, you can expect a price quote from InstaCarrier in a few moments.": "Check your inbox, you can expect a price quote from InstaCarrier in a few moments.",
  "Having any questions?": "Having any questions?",
  "You have to choose one.": "You have to choose one.",
  "Invalid contact method": "Invalid contact method",
  "Order summary": "Order summary",
  "Check the order you entered and confirm its correctness with the button at the bottom.": "Check the order you entered and confirm its correctness with the button at the bottom.",
  "Your order has been sent for processing. An assigned operator will contact you shortly.": "Your order has been sent for processing. An assigned operator will contact you shortly.",
  "Route details": "Route details",
  "Cargo information": "Cargo information",
  "Your price without VAT": "Your price without VAT",
  "Distance": "Distance",
  "Duration": "Duration",
  "URL copied successfully": "URL copied successfully",
  "Transportation order": "Transportation order",
  "Finish your transportation order.": "Finish your transportation order.",
  "Share a price quote": "Share a price quote",
  "Offer expires in": "Offer expires in",
  "Delivery": "Delivery",
  "Your message has been submitted. Thank you!": "Your message has been submitted. Thank you!",
  "Please specify {type} which has at least city and country.": "Please specify {type} which has at least city and country.",
  "Share with us your opinion at ": "Share with us your opinion at ",
  "Do you have an idea about how to improve our services? Share it with us at ": "Do you have an idea about how to improve our services? Share it with us at ",
  "Latest news, guides and posts": "Latest news, guides and posts",
  "Stay updated with the latest insights and tips from our transport and technology experts.": "Stay updated with the latest insights and tips from our transport and technology experts.",
  "Go to InstaCarrier Blog": "Go to InstaCarrier Blog",
  "Please email us at the following email address listed below or by filling out the form.": "Please email us at the following email address listed below or by filling out the form.",
  "Blog": "Blog",
  "It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.": "It is simple. Choose what you need to transport, and SWIDA Innovative will take care of the rest.",
  "Get a FREE quote now": "Get a FREE quote now",
  "Get a free quote now": "Get a free quote now",
  "Get the experience of booking freight shipments in the 21st century.": "Get the experience of booking freight shipments in the 21st century.",
  "The quote has been canceled, please create a new one.": "The quote has been canceled, please create a new one.",
  "years on the market": "years on the market",
  "Unit length": "Unit length",
  "The selected datetime must be within the next 30 days": "The selected datetime must be within the next 30 days",
  "Date and time is required": "Date and time is required",
  "Invalid date and time": "Invalid date and time",
  "LATEST PICKUP": "LATEST PICKUP",
  "LATEST DELIVERY": "LATEST DELIVERY",
  "Order confirmed": "Order confirmed",
  "hour": "hour | hours",
  "(VAT included)": "(VAT included)",
  "(VAT not included)": "(VAT not included)",
  "Custom pricing": "Custom pricing",
  "Get your price in 3 minutes. Pay Later!": "Get your price in 3 minutes. Pay Later!",
  "Your e-mail address has been confirmed, you can log in.": "Your e-mail address has been confirmed, you can log in.",
  "Email was already verified or url is no longer valid.": "Email was already verified or url is no longer valid.",
  "Explore this quick and ease way to get price offers for your transports": "Explore this quick and ease way to get price offers for your transports",
  "Confirming email": "Confirming email",
  "Account settings": "Account settings",
  "We need additional information to proceed your request.": "We need additional information to proceed your request.",
  "General": "General",
  "Change password": "Change password",
  "Login": "Login",
  "Password is required": "Password is required",
  "The password reset link has expired": "The password reset link has expired",
  "Password reset": "Password reset",
  "Password was successfully reset. Now you can login to the application.": "Password was successfully reset. Now you can login to the application.",
  "Navigate to login": "Navigate to login",
  "New password": "New password",
  "New password again": "New password again",
  "Reset password": "Reset password",
  "Create account": "Create account",
  "Your data has been updated successfully.": "Your data has been updated successfully.",
  "Could not update user. Please try again later.": "Could not update user. Please try again later.",
  "E-MAIL": "E-MAIL",
  "PHONE": "PHONE",
  "e.g. +4211111111": "e.g. +4211111111",
  "NAME": "NAME",
  "Save changes": "Save changes",
  "Old password is required": "Old password is required",
  "New Password is required": "New Password is required",
  "Password changed successfully.": "Password changed successfully.",
  "Could not change password. Please try again later.": "Could not change password. Please try again later.",
  "OLD PASSWORD": "OLD PASSWORD",
  "NEW PASSWORD": "NEW PASSWORD",
  "NEW PASSWORD AGAIN": "NEW PASSWORD AGAIN",
  "Update password": "Update password",
  "Already customer?": "Already customer?",
  "Sign in": "Sign in",
  "Login and get price": "Login and get price",
  "Password recovery": "Password recovery",
  "Email with the instructions was sent to the address provided.": "Email with the instructions was sent to the address provided.",
  "Follow the instructions and get back to the application.": "Follow the instructions and get back to the application.",
  "Create account and get price": "Create account and get price",
  "Continue without an account": "Continue without an account",
  "User Menu": "User Menu",
  "Sign Out": "Sign Out",
  "Sign In": "Sign In",
  "Why to register?": "Why to register?",
  "Get instant quotes": "Get instant quotes",
  "Submit your pickup location and receive a price in just 3 minutes without e-mail verification.": "Submit your pickup location and receive a price in just 3 minutes without e-mail verification.",
  "Track your shipments": "Track your shipments",
  "Easily access your shipping history and stay updated on your deliveries.": "Easily access your shipping history and stay updated on your deliveries.",
  "Unlock exclusive rewards": "Unlock exclusive rewards",
  "Be the first to know about special offers and promotions tailored for our valued customers.": "Be the first to know about special offers and promotions tailored for our valued customers.",
  "Is the company you are ordering for a VAT payer in your country?": "Is the company you are ordering for a VAT payer in your country?",
  "COMPANY NAME": "COMPANY NAME",
  "VAT ID": "VAT ID",
  "Street": "Street",
  "Street number": "Street number",
  "ZIP Code": "ZIP Code",
  "Verification email sent": "Verification email sent",
  "Failed to resend verification email.": "Failed to resend verification email.",
  "Could not login user. Please try again later.": "Could not login user. Please try again later.",
  "Don’t have an account?": "Don’t have an account?",
  "Create new account": "Create new account",
  "Resend verification email": "Resend verification email",
  "PASSWORD": "PASSWORD",
  "e.g. John Smith": "e.g. John Smith",
  "You must agree with Terms and conditions": "You must agree with Terms and conditions",
  "Confirmation e-mail was sent to your e-mail address.": "Confirmation e-mail was sent to your e-mail address.",
  "Could not register user. Please try again later.": "Could not register user. Please try again later.",
  "Already registered? ": "Already registered? ",
  "PASSWORD AGAIN": "PASSWORD AGAIN",
  "Are you shopping as an individual or a company?": "Are you shopping as an individual or a company?",
  "Company": "Company",
  "Individual": "Individual",
  "Please specify pick-up and delivery addresses.": "Please specify pick-up and delivery addresses.",
  "Failed to update waypoints": "Failed to update waypoints",
  "To ensure our dispatch team can reach you when necessary, please provide your phone number. Your understanding and cooperation are greatly appreciated.": "To ensure our dispatch team can reach you when necessary, please provide your phone number. Your understanding and cooperation are greatly appreciated.",
  "Discount Code": "Discount Code",
  "Apply": "Apply",
  "Product selection": "Product selection",
  "We offer you a choice of four products for your transport based on the time of loading and unloading. Choose which one best meets your speed and price requirements.": "We offer you a choice of four products for your transport based on the time of loading and unloading. Choose which one best meets your speed and price requirements.",
  "Transit time:": "Transit time:",
  "Regular vehicle": "Regular vehicle",
  "position check": "position check",
  "Verified carriers": "Verified carriers",
  "by SWIDA": "by SWIDA",
  "Order now": "Order now",
  "Custom time": "Custom time",
  "Pickup until": "Pickup until",
  "Delivery until": "Delivery until",
  "Pickup between": "Pickup between",
  "Can not be empty": "Can not be empty",
  "Downtime duration is required if option is selected": "Downtime duration is required if option is selected",
  "Duration have to be more than 0": "Duration have to be more than 0",
  "Number": "Number",
  "Get express road shipment around Europe with a full control of your shipment with live shipment dashboard.": "Get express road shipment around Europe with a full control of your shipment with live shipment dashboard.",
  "Over 40,000 items delivered in last year": "Over 40,000 items delivered in last year",
  "Temperature-controlled vehicle ": "Temperature-controlled vehicle ",
  "Frigo": "Frigo",
  "Device to load and unload heavy items": "Device to load and unload heavy items",
  "Transportation of dangerous goods based on UN conditions": "Transportation of dangerous goods based on UN conditions",
  "Driver actively assists in the loading of goods": "Driver actively assists in the loading of goods",
  "Assisted loading ": "Assisted loading ",
  "Driver actively assists in the unloading of goods": "Driver actively assists in the unloading of goods",
  "Assisted unloading": "Assisted unloading",
  "Downtime of the vehicle due to longer loading or unloading": "Downtime of the vehicle due to longer loading or unloading",
  "Vehicle downtime": "Vehicle downtime",
  "Loading area length up to 4.8 meters": "Loading area length up to 4.8 meters",
  "Extended loading area": "Extended loading area",
  "km": "km",
  "Coming soon": "Coming soon",
  "Price starting from": "Price starting from",
  "Customer name and surname": "Customer name and surname",
  "Customer e-mail": "Customer e-mail",
  "To see final price and shipping option please add cargo details first. The final price depends on the size and weight of the goods as well as the required loading time": "To see final price and shipping option please add cargo details first. The final price depends on the size and weight of the goods as well as the required loading time",
  "Count should not be greater than 250": "Count should not be greater than 250",
  "You must agree to the terms and conditions": "You must agree to the terms and conditions",
  "Ordering is only possible for company customers. Please use a company email account.": "Ordering is only possible for company customers. Please use a company email account.",
  "hours": "hours",
  "We have already started to work on your request. You can expect a price quote in your inbox in the next 30 minutes.": "We have already started to work on your request. You can expect a price quote in your inbox in the next 30 minutes.",
  "Your price quote is on its way to ": "Your price quote is on its way to ",
  "Vehicle": "Vehicle",
  "Plate number": "Plate number",
  "Your dispatcher": "Your dispatcher",
  "No payment method selected": "No payment method selected",
  "Your price with VAT": "Your price with VAT",
  "discount applied": "discount applied",
  "driver assisted loading": "driver assisted loading",
  "driver assisted unloading": "driver assisted unloading",
  "vehicle downtime": "vehicle downtime",
  "Service and price": "Service and price",
  "placeholder_email_example": "",
  "Company is VAT registered": "Company is VAT registered",
  "You need to specify { pickup } which has at least city and country.": "You need to specify { pickup } which has at least city and country.",
  "You need to specify { delivery } which has at least city and country.": "You need to specify { delivery } which has at least city and country.",
  "Click to View Interactive Map": "Click to View Interactive Map",
  "Powered by SWIDA Innovative © 2024 - instacarrier.eu": "Powered by SWIDA Innovative © 2024 - instacarrier.eu",
  "Pickup date is invalid": "Pickup date is invalid",
  "Pickup date is required": "Pickup date is required",
  "Pickup time must be between {start}:00 and {end}:00": "Pickup time must be between {start}:00 and {end}:00",
  "Delivery date is invalid": "Delivery date is invalid",
  "Delivery date is required": "Delivery date is required",
  "Delivery time must be between {start}:00 and {end}:00": "Delivery time must be between {start}:00 and {end}:00",
  "No updates available": "No updates available",
  "Goods unloaded": "Goods unloaded",
  "Arrived for loading": "Arrived for loading",
  "Arrived for unloading": "Arrived for unloading",
  "Goods loaded": "Goods loaded",
  "Unknown status": "Unknown status",
  "You will see location updates soon": "You will see location updates soon",
  "Searching for a vehicle...": "Searching for a vehicle...",
  "Transport order": "Transport order",
  "Transport detail": "Transport detail",
  "Transport status:": "Transport status:",
  "Transit history": "Transit history",
  "Product option": "Product option",
  "Selected product option": "Selected product option",
  "Description": "Description",
  "Track your order here!": "Track your order here!",
  "Applied discounts": "Applied discounts",
  "Vehicle Type": "Vehicle Type",
  "You need to specify { type } which has at least city and country.": "You need to specify { type } which has at least city and country.",
  "Looks like you would like to { loadType } goods in a country or region our system is not able to quote automatically. Don't worry, we'll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.": "Looks like you would like to { loadType } goods in a country or region our system is not able to quote automatically. Don't worry, we'll do our best to help you out! Please send us email about your request, and we will get in touch with you ASAP.",
  "poweredBy": "Powered by SWIDA Innovative © {year} - instacarrier.eu",
  "The weight of your packages specified in your transportation request exceeds the limit of the vehicle equipped with tail lift, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.": "The weight of your packages specified in your transportation request exceeds the limit of the vehicle equipped with tail lift, but don\\'t worry, we\\'ll do our best to help you out! Choose a larger vehicle type or send us an email about your request and we will get back to you as soon as possible.",
  "Loading area length up to 7.5 meters": "Loading area length up to 7.5 meters",
  "Loading area height up to 3 meters": "Loading area height up to 3 meters",
  "Downtime of the vehicle for more than 2 hours due to longer loading or unloading": "Downtime of the vehicle for more than 2 hours due to longer loading or unloading",
  "NEW": "NEW",
  "Plate number trailer": "Plate number trailer",
  "Order Manager": "Order Manager",
  "We are": "We are",
  " currently searching ": " currently searching ",
  "for the perfect vehicle": "for the perfect vehicle"
}
