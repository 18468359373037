<script setup lang="ts">
import FeedbackForm from '@/views/free-quote/create/FeedbackForm.vue'

const currentYear = computed(() => new Date().getFullYear())
</script>

<template>
  <div id="contact" class="text-light pb-5 pt-2">
    <div class="row justify-content-center mb-5">
      <div class="col-lg-4 justify-content-center">
        <div class="row">
          <div class="col-auto">
            <h3 class="mt-5">
              {{ $t('Contact us') }}
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <p class="mb-5">
              {{ $t('Please email us at the following email address listed below or by filling out the form.') }}
            </p>
          </div>
        </div>

        <!--        <div class="d-flex align-items-center"> -->
        <!--          <img src="@images/ic_phone.svg" alt="phone icon" class="me-2"> -->
        <!--          <p class="mb-0"> -->
        <!--            +421 944 121 444 -->
        <!--          </p> -->
        <!--        </div> -->
        <div class="d-flex align-items-center mt-3">
          <img src="@images/ic_email.svg" alt="phone icon" class="me-2">
          <p class="mb-0">
            quote@instacarrier.eu
          </p>
        </div>
        <div>
          <p class="mt-2 p-1">
            SWIDA Innovative s.r.o.<br>
            Popradská 155/56<br>
            040 11 Košice<br>
            Slovakia<br>
            VAT: SK2020030639
          </p>
        </div>
      </div>
      <div class="col-lg-5 justify-content-center mt-5">
        <FeedbackForm />
      </div>
    </div>
    <hr class="text-gray">
    <div class="row py-3">
      <div class="col-lg-2 col-6">
        <a class="text-gray" href="/static/files/privacy.pdf" target="_blank"> {{ $t('Privacy policy') }}</a>
      </div>
      <div class="col-lg-2 col-6">
        <a class="text-gray" href="/static/files/terms.pdf" target="_blank"> {{ $t('Terms and Conditions') }} </a>
      </div>
      <div class="col-auto text-gray ms-lg-auto mt-lg-0 mt-3">
        <a
          class="text-gray" href="https://swida.sk/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t('poweredBy', { year: currentYear }) }}
        </a>
      </div>
    </div>
  </div>
</template>
