import { acceptHMRUpdate, defineStore } from 'pinia'
import { getVehicleByType } from '@/utils/vehicle'
import type {
  ApplyDiscount,
  ContactMethodEnum,
  DefaultPriceRequest,
  Hash,
  Good as OriginalGood, PaymentMethodEnum,
  PriceOption,
  QuoteByHash,
  QuoteByID,
  RouteDetailsRequest,
  UpdateQuoteSpecialRequirements,
  UpdateUserBillingType, Utm,
  Uuid, Waypoint,
} from '@apiTypes'
import { quoteTotalWeight } from '@/utils/quote.utils'
import api from '@api'

export interface Good extends OriginalGood {
  tempId?: number
}

export const useQuoteStore = defineStore('quote', {
  state: () => ({
    quoteByID: {} as QuoteByID,
    quoteByHash: {} as QuoteByHash,
  }),
  getters: {
    getQuoteByID: state => state.quoteByID,
    getQuoteByHash: state => state.quoteByHash,
    totalWeight: state => {
      const goodsWeight = quoteTotalWeight(state.quoteByID.goods || state.quoteByHash.goods)
      const vehicle = getVehicleByType(state.quoteByID.vehicle_type || state.quoteByHash.vehicle_type)
      const isTailLift = state.quoteByID.special_requirements?.tail_lift || state.quoteByHash.special_requirements?.tail_lift
      const weight = isTailLift ? vehicle.tailLiftWeight : vehicle.weight

      return goodsWeight || weight
    },
  },
  actions: {
    async fetchQuoteByHash(hash: string) {
      const response = await api.hash.quotesByHashRetrieve(hash)

      this.quoteByHash = response.data as QuoteByHash

      return response.data
    },
    async createQuote(payload: Partial<QuoteByID>) {
      const response = await api.quotesByIdCreate(<QuoteByID>payload)
      if (response)
        this.quoteByID = response.data
    },
    async updateQuote(params: Uuid.QuotesByIdPartialUpdate.RequestBody) {
      const response = await api.uuid.quotesByIdPartialUpdate(this.quoteByID.uuid as string, params)
      if (response)
        this.quoteByID = { ...this.quoteByID, ...response.data }
    },
    async updateUtmParams(utmParams: Utm) {
      const response = await api.uuid.quotesByIdUpdateUtmCreate(this.quoteByID.uuid as string, utmParams)
      if (response)
        this.quoteByID = { ...this.quoteByID, ...response.data }
    },
    async markMagicLinkVisited() {
      return await api.hash.quotesByHashMarkMagicLinkVisitedCreate(this.quoteByHash.hash as string)
    },
    async updateQuoteByHash(params: Hash.QuotesByHashPartialUpdate.RequestBody) {
      const response = await api.hash.quotesByHashPartialUpdate(this.quoteByHash.hash as string, params)
      if (response)
        this.quoteByHash = { ...this.quoteByHash, ...response.data }
    },
    async confirmQuote(hash: string) {
      const response = await api.hash.quotesByHashConfirmCreate(hash)
      if (response)
        this.quoteByHash = response.data
    },
    addGood(good: Partial<Good>) {
      const tempId = Date.now()

      const newGood = {
        ...good,
        tempId,
      }

      if (!this.quoteByID.goods)
        this.quoteByID.goods = []

      this.quoteByID.goods.push(newGood as Good)
    },
    isMatchingGood(good: Good, updatedGood: Partial<Good>) {
      const matchesTempId = updatedGood.tempId !== undefined && good.tempId === updatedGood.tempId
      const matchesId = updatedGood.id !== undefined && good.id === updatedGood.id

      return matchesTempId || matchesId
    },
    updateGood(updatedGood: Partial<Good>) {
      if (!this.quoteByID.goods)
        this.quoteByID.goods = []

      this.quoteByID.goods = this.quoteByID.goods.map(good =>
        this.isMatchingGood(good, updatedGood) ? { ...good, ...updatedGood } : good,
      )
    },
    removeGood(identifier: number) {
      if (!this.quoteByID.goods)
        this.quoteByID.goods = []

      this.quoteByID.goods = this.quoteByID.goods.filter((g: Good) => g.tempId !== identifier && g.id !== identifier)
    },
    resetQuote() {
      this.quoteByID = {} as QuoteByID
    },
    async updateWaypoint(waypoint: Waypoint, index?: number) {
      if (!this.quoteByHash.waypoints)
        this.quoteByHash.waypoints = []

      if (index !== undefined && index !== -1) {
        const updatedWaypoint = await api.uuid.quotesByIdUpdateWaypointCreate(this.quoteByID.uuid as string, waypoint)

        this.quoteByHash.waypoints[index] = { ...this.quoteByHash.waypoints[index], ...updatedWaypoint.data }
      }
      else {
        this.quoteByHash.waypoints.push(waypoint)
      }
    },
    async updateWaypoints(waypoints: Partial<Waypoint>[]) {
      const response = await api.hash.quotesByHashUpdateQuoteWaypointsCreate(this.quoteByHash.hash as string, { waypoints })

      this.quoteByHash = response.data

      return response.data
    },
    updateContactMethod(method: ContactMethodEnum) {
      this.quoteByID.contact && (this.quoteByID.contact.contact_method = method)
    },
    async routeDetails(data: RouteDetailsRequest) {
      const response = await api.uuid.quotesByIdRouteDetailsCreate(this.quoteByID.uuid as string, data)
      if (response) {
        this.quoteByID.polyline = response.data.polyline
        this.quoteByID.duration = response.data.duration
        this.quoteByID.distance = response.data.distance
      }

      return response.data
    },
    async defaultPrice(data: DefaultPriceRequest) {
      return api.uuid.quotesByIdGetDefaultPriceCreate(this.quoteByID.uuid as string, data)
    },
    setQuoteCustom(custom: boolean) {
      this.quoteByID.quote_custom = custom
    },
    fetchSessionId(hash: string) {
      return api.checkoutSession.paymentsCheckoutSessionCreate(hash)
    },
    async updateBilling(hash: string, billing_info: object) {
      const response = await api.hash.quotesByHashUpdateBillingCreate(hash, { billing_info })

      this.quoteByHash = response.data

      return response.data
    },
    async updatePayment(hash: string, payment_method: PaymentMethodEnum) {
      const response = await api.hash.quotesByHashUpdatePaymentCreate(hash, { payment_method })

      this.quoteByHash = response.data

      return response.data
    },
    autofillCompany(hash: string, vat_number: string, country: string) {
      return api.hash.quotesByHashAutofillCompanyCreate(hash, {
        vat_number,
        country,
      })
    },
    runCofaceTask(hash: string) {
      return api.hash.quotesByHashCofaceTaskCreate(hash)
    },
    async selectPriceOption(hash: string, priceOption: PriceOption) {
      const response = await api.hash.quotesByHashUpdateSelectedPriceOptionCreate(hash, { selected_price_option: priceOption })

      this.quoteByHash = response.data

      return response.data
    },
    async updateSpecialRequirements(hash: string, specialRequirements: UpdateQuoteSpecialRequirements) {
      const response = await api.hash.quotesByHashUpdateSpecialRequirementsCreate(hash, specialRequirements)

      this.quoteByHash = response.data

      return response.data
    },
    async fetchQuoteWithOrder(hash: string) {
      const response = await api.hash.quotesByHashWithOrderRetrieve(hash)

      this.quoteByHash = response.data

      return response.data
    },
    async updateQuoteUserType(hash: string, userType: UpdateUserBillingType) {
      const response = await api.hash.quotesByHashUpdateQuoteUserTypeCreate(hash, userType)

      this.quoteByHash = response.data

      return response.data
    },
    async applyDiscount(payload: ApplyDiscount) {
      const response = await api.apply.discountsApplyCreate(payload)
      if (response)
        this.quoteByHash = response.data
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useQuoteStore, import.meta.hot))
